import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Footer from './Footer';
import Header from './Header';

import moment from 'moment';
import ReactGA from 'react-ga4';
import { motion, AnimatePresence } from "framer-motion";

export default class Resume extends Component {
    render() {
        let resumeData = this.props.resumeData;
    
        ReactGA. send ({ 
          hitType: "pageview", 
          page: "/resume",
          title: "Resume",
        });
      
      
        const routeVariants = {
            initial: {
                y: '50vh'
            },
            final: {
                y: '0vh',
                transition: {
                  type: "spring",
                  mass: 0.4,
                },
            }
        };
        
        return (
            <div className="container">
            <Header resumeData={resumeData} />
            <motion.div
                variants={routeVariants}
                initial="initial"
                animate="final"
            >
                <section id="resume">
                    <div className="row work">
                        <div className="one-third">
                            <h1><span>Work</span></h1>
                        </div>
                        <div className="two-thirds">
                            {
                            resumeData.work && resumeData.work.map((item) => {
                  
                                moment().format("MMM d YYYY");
                                const now = moment(new Date());
                  
                                let startFull = item.start.split(" ");
                                let endFull = item.end.split(" ");
                    
                                let start = moment(startFull[0] + " 1 " + startFull[1]);
                                let end = moment(endFull[0] + " 1 " + endFull[1]);
                    
                                if (item.end === "Present") {
                                    end = moment(now).format("MMM d YYYY");
                                }
                    
                                let duration = start.diff(end, 'years', true);
                    
                                duration = Math.abs(duration);
                                duration= Math.round(duration * 10) / 10
                    
                                //console.log(end);
                    
                                return(
                                    <div className="item" key={item.CompanyName}>
                                        <div className="job-header">
                                            <img src={item.logo} />
                                            <div className="job-info">
                                                <h3>{item.CompanyName}</h3>
                                                <p className="info">{item.specialization} <span> &bull;</span> {item.location} <span> &bull;</span> <em className="date">{item.start} &ndash; {item.end} <span className="small">({duration}y)</span></em></p>
                                            </div>
                                        </div>
                                        <p>{item.Achievements}</p>
                                    </div>

                                )
                            })
                            }
                        </div> 
                    </div>
                    <div className="row projects">
                        <div className="one-third">
                            <h1><span>Projects</span></h1>
                        </div>
                        <div className="two-thirds">
                            {
                            resumeData.projects && resumeData.projects.map((item)=>{
                                return(
                                    <div className="item" key={item.name}>
                                        <h3>{item.name}</h3>
                                        <p className="info">
                                            {item.title}
                                            <span> &bull;</span> <em className="date">{item.start} &ndash; {item.end}</em>
                                        </p>
                                        <p>{item.Achievements}</p>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                    <div className="row education">
                        <div className="one-third">
                            <h1><span>Education</span></h1>
                        </div>
                        <div className="two-thirds">
                            {
                            resumeData.education && resumeData.education.map((item)=>{
                                return(
                                    <div className="item" key={item.UniversityName}>
                                        <h3>{item.UniversityName}</h3>
                                        <p className="info">
                                            {item.specialization}
                                            <span> &bull;</span> <em className="date">{item.start} &ndash; {item.end}</em>
                                        </p>
                                        <p>{item.Achievements}</p>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                    <div className="row skill">
                        <div className="one-third">
                            <h1><span>Skills</span></h1>
                        </div>
                        <div className="two-thirds">
                            <p className="skills-description">
                                {resumeData.skillsDescription}
                            </p>
                            <div className="bars">
                                <ul className="skills">
                                    {
                                    resumeData.skills && resumeData.skills.map((item) => {
                                        return(
                                            <li key={item.skillname}>
                                                <span className={`bar-expand ${item.level.toLowerCase()}`}></span>
                                                <em>{item.skillname}</em>
                                            </li>
                                        )
                                    })
                                    }
   					            </ul>
   				             </div>
                        </div>
                    </div>
                </section>
            </motion.div>
            <Footer resumeData={resumeData} />
            </div>
        );
    }
}