import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

import { FaLinkedinIn } from "react-icons/fa";
import { FaGithub } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaMedium } from "react-icons/fa6";

export default class Availability extends Component {
  render() {
    let resumeData = this.props.resumeData;
      
    tippy('[data-tippy-content]', {
        content: '',
        duration: 500,
        arrow: true,
        delay: [1000, 200],
        touch: ['hold', 500],
        arrow: false,
    });
      
    return (
       <ul className="social">
            {
              resumeData.socialLinks && resumeData.socialLinks.map((item)=>{
                return(
                  <li className="social-link" key={item.className} data-tippy-content={item.name}>
                    <a href={item.url} target="_blank">
                       {item.className === "linkedin" ? <FaLinkedinIn /> : (item.className === "github" ? <FaGithub /> : (item.className === "instagram" ? <FaInstagram /> : (item.className === "medium" ? <FaMedium /> : "")))}
                    </a>
                  </li>
                )
              })
            }
          </ul>
        );
  }
}

//<ReactTidyCal className="three columns" path="joshsroufe/intro" />