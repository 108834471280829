import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Social from './Social';
import Nav from './Nav';
import ThemeSwitch from './ThemeSwitch';

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import { motion, AnimatePresence } from "framer-motion";

export default class Footer extends Component {
  render() {
    let resumeData = this.props.resumeData;
    
    //tippy('#onesignal-bell-container', {
    //    content: 'Manage Notifications',
    //    duration: 500,
    //    arrow: true,
    //    delay: [1000, 200],
    //    offset: [48, 48],
    //    touch: ['hold', 500],
    //    theme: 'notification-tooltip',
    //    arrow: false,
    //});
    
    function handleRefresh() {
      window.location.reload(true);
    } 
      
    const childVariants = {
      initial: {
        opacity: 0,
        y: "200px",
      },
      final: {
        opacity: 1,
        y: "0px",
        transition: {
          duration: 0.3,
          delay: 0,
          type: "Spring",
          mass: 0.4,
        },
      },
    };
      
    return (
      <motion.footer
      variants={childVariants} 
      initial="initial" 
      animate="final" 
      >
        <Nav  />
        <div className="row">
            <div className="twelve columns">
                <Social resumeData={resumeData} />
            </div>
        </div>
        <ThemeSwitch />
    </motion.footer>
    );
  }
}



//<span className="refresh" onClick={handleRefresh}>Refresh</span>