import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Footer from './Footer';
import Header from './Header';
import ContactForm from './ContactForm';
import Availability from './Availability';

//import { ReactTidyCal } from "react-tidycal";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReactGA from 'react-ga4';
import { motion, AnimatePresence } from "framer-motion";

export default class ContactUs extends Component {
    
  render() {     
  
    let resumeData = this.props.resumeData;
    ReactGA. send ({ 
      hitType: "pageview", 
      page: "/contact",
      title: "Contact",
    });
      
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      }
    };
      
    const routeVariants = {
        initial: {
            y: '50vh'
        },
        final: {
            y: '0vh',
            transition: {
              type: "spring",
              mass: 0.4,
            },
        }
    };
      
    return (
        <div className="container">
        <Header resumeData={resumeData} />
        <motion.div
            variants={routeVariants}
            initial="initial"
            animate="final"
        >
            <section id="contact">
                <div className="row">
                    <div className="content">
                        <div id="meetings" className="half">
                            <div className="meeting-container">
                                <div className="meetings-label">
                                    <h4>Schedule a Meeting</h4>
                                    <Availability resumeData={resumeData} />
                                </div>
                                {
                                    resumeData.availability === "unavailable" && 
                                        <div>
                                            <div className="meeting-header">
                                                <h3>{resumeData.meetings[0].title}</h3>
                                                <span className="small">({resumeData.meetings[0].duration} min)</span> 
                                            </div>
                                            <p>{resumeData.meetings[0].description}</p>
                                            <a href={resumeData.meetings[0].url} target="_blank"><button className="book">Book now {resumeData.meetings[0].price === 0 ? '' : "$"+resumeData.meetings[0].price}</button></a>
                                        </div>
                                }
                                {
                                    resumeData.availability === "available" && 
                                        <Carousel 
                                            responsive={responsive}
                                            swipeable={true}
                                            showDots={true}
                                            infinite={true}
                                            arrows={false}
                                            deviceType={this.props.deviceType}
                                            autoPlay={this.props.deviceType !== "mobile" ? true : false}
                                            autoPlaySpeed={10000}
                                            dotListClass="pagination"
                                            minimumTouchDrag="50"
                                        >
                                        {
                                            resumeData.meetings && resumeData.meetings.map((item)=>{
                                                return(
                                                    <div key={item.title}>
                                                        <div className="meeting-header">
                                                            <h3>{item.title}</h3>
                                                            <span className="small">({item.duration >= 60 ? item.duration / 60 : item.duration} {item.duration >= 60 ? "hr" : "min"})</span>    
                                                        </div>
                                                        <p>{item.description}</p>
                                                        <a href={item.url} target="_blank"><button className="book">Book now {item.price === 0 ? '' : "$"+item.price}</button></a> 
                                                    </div>
                                                )
                                            })
                                        }
                                        </Carousel>
                                }
                            </div>
                        </div>
                        <div id="messages" className="half">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </section>
        </motion.div>
        <Footer resumeData={resumeData} />
        </div>
    );
  }
}

//<Carousel 
//    responsive={responsive}
//    swipeable={true}
//    showDots={true}
//    infinite={true}
//    autoPlay={this.props.deviceType !== "mobile" ? true : false}
//    autoPlaySpeed={10000}
//    removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
//    deviceType={this.props.deviceType}
//    dotListClass="pagination"
//    minimumTouchDrag="50"
//>
//{
//resumeData.meetings && resumeData.meetings.map((item)=>{
//  return(
//
//        <div key={item.title}>
//      { /*<img src={item.img} className="photo" /> */}
//            <div className="meeting-header">
//                <h3>{item.title}</h3>
//                <span className="duration">({item.duration} min)</span>    
//            </div>
//            <p>{item.description}</p>
//            <a href={item.url} target="_blank"><button className="book">Book now {item.price === 0 ? '' : "$"+item.price}</button></a> 
//          </div>
//        )
//    })
//  }
//</Carousel>

//<div className="twelve columns">
//    <h2>Ready to connect?</h2>
//    <p>Use the form to send a message or you can request a meeting.</p>
//</div>

//<ReactTidyCal path="joshsroufe/intro" />
//





