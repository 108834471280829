import React, { useState } from "react";
import { RWebShare } from "react-web-share";

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

import { FaShare } from "react-icons/fa";
import { FaSquareShareNodes } from "react-icons/fa6";
import { FaShareSquare } from "react-icons/fa";
import { FiShare } from "react-icons/fi";
import { TbShare2 } from "react-icons/tb";
import { RiShare2Fill } from "react-icons/ri";

const Share = () => {
    
    const url = window.location.href;
    const pieces = url.split("/");
    let page = pieces[pieces.length - 1];
    const section = pieces[pieces.length - 2];
    
    if (page.includes("?")) {
      page = page.replace("?", "-");
    }
  
    const sentences =[page]; 
    const splitSentences = sentences.map((sentence) => sentence.split("-"));
    const capitalizeSplitSentences = splitSentences.map(sentence => sentence.map((word) => word.charAt(0).toUpperCase() + word.slice(1)));
    const joinedCapitalizedSplitSentences = capitalizeSplitSentences.map((sentences) => sentences.join(" "));
    page = joinedCapitalizedSplitSentences[0];
  
  
    let action_title = "";
  
    if (section === "projects") {
      action_title = "Josh Sroufe — " + page.replace(/-/g, ' ') + " " + section.charAt(0).toUpperCase() + section.slice(1);
    }
    else {
      action_title = "Josh Sroufe — " + page.replace(/-/g, ' ');
    }
    
    
    tippy('.share-button', {
        content: 'Share ' + action_title,
        duration: 500,
        arrow: true,
        delay: [1000, 200],
        touch: ['hold', 500],
        arrow: false,
      });
    
  return (
    <div>
      <RWebShare
        data={{
          text: "Check this out from Josh Sroufe",
          url: url,
          title: action_title,
        }}
        onClick={() => console.log("shared successfully!")}
      >
          <span className="share-button"><RiShare2Fill /></span> 
      </RWebShare>
    </div>
  );
};

export default Share;