import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Availability extends Component {
  render() {
    let resumeData = this.props.resumeData;
      
    const url = window.location.href;
    const pieces = url.split("/");
    const page = pieces[pieces.length - 1];
    const section = pieces[pieces.length - 2];
      
    const freelancing = <div className="freelancing"><span className={"status " + resumeData.availability}></span> <span className="availability">{resumeData.availability}</span> for Consulting</div>
    
    if (page !== "contact" && resumeData.availability !== "unavailable") {
        return(
            <Link to="/contact">
                {freelancing}
            </Link> 
        )     
    }
    else {
        return (
            freelancing
        );
    }  
  }
}

//<ReactTidyCal className="three columns" path="joshsroufe/intro" />