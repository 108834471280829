"use client";

import { useEffect } from "react";

import OneSignal from 'react-onesignal';

export default function ScrollToTop() {

  useEffect(() => {
    // Ensure this code runs only on the client side
    if (typeof window !== 'undefined') {
      OneSignal.init({
        appId: '1713e583-3f2c-4fb3-9805-9f60b334b35b',
        // You can add other initialization options here
        notifyButton: {
          enable: true,
        },
        // Uncomment the below line to run on localhost. See: https://documentation.onesignal.com/docs/local-testing
         allowLocalhostAsSecureOrigin: true
      });
    }
  }, []);

  return;
}