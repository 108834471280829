import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from 'react-router-dom';


import Footer from './Footer';
import Header from './Header';

import ReactGA from 'react-ga4';
import moment from 'moment';
import { motion, AnimatePresence } from "framer-motion";

const Blog = (props) => {
  const [posts, setPosts] = useState([]);
  const getPostData = () => {
    axios
      .get("https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@joshsroufe")
      .then((res) => {
        setPosts(res.data.items);
      })
      .catch((error) => {
        console.error("Error fetching blog posts:", error);
      });
  };
  useEffect(() => {
    getPostData();
  }, []);
    
  function removeTags(str) {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();

        // Regular expression to identify HTML tags in
        // the input string. Replacing the identified
        // HTML tag with a null string.
        return str.replace(/(<([^>]+)>)/ig, '');
    }
    
  let resumeData = props.resumeData;
    
    useEffect(() => {
        ReactGA. send ({ 
          hitType: "pageview", 
          page: "/blog",
          title: "Blog",
        });
    }, []);
    
    const routeVariants = {
        initial: {
            y: '50vh'
        },
        final: {
            y: '0vh',
            transition: {
              type: "spring",
              mass: 0.4,
            },
        }
    };
 
  //console.log(posts);
  
  return (
      <div className="container">
        <Header resumeData={resumeData} />
        <motion.div
            variants={routeVariants}
            initial="initial"
            animate="final"
        >
            <section id="blogs">
                <div className="row">
                 { posts &&   
                    <div id="blog">
                        {
                        posts.map((post, index) => (
                            <div key={post.guid} className={index === posts.length -1 ? "last post" : "post"}>
                                <img className="one-third" src={post.description.toString().match(/<img[^>]+src="([^">]+)"/)[1]} />
                                <div className="two-thirds">
                                    <div className="title">
                                        <h2>{post.title}</h2>
                                        <span className="small">{moment(post.pubDate).format("MMM Do, YYYY")}</span>
                                    </div>
                                    <p> {removeTags(post.content.substring(0, 500))}... </p>
                                    <Link to={"/blog-post?" + post.title.replace(/\s+/g, '-').toLowerCase()} state={posts} >Read more</Link>
                                </div>
                            </div>
                        ))
                        }
                    </div>
                  }
                </div>
            </section>
        </motion.div>
        <Footer resumeData={resumeData} />
      </div>
  );
};
export default Blog;

//<p dangerouslySetInnerHTML={{ __html: post.content }} />